
const BACKEND_ENVS = Object.freeze({
  DEVELOPMENT: 'development',
  PRODUCTION: 'production'
});

const BACKEND_GRAPHQL_URL =
    (process.env.NODE_ENV ==='production' || process.env.REACT_APP_BACKEND_ENV === BACKEND_ENVS.PRODUCTION) ?
      'https://icast-backend-dev.herokuapp.com/graphql/' :
      'https://icast-backend-dev.herokuapp.com/graphql/';

// const BACKEND_GRAPHQL_URL =
//     (process.env.NODE_ENV ==='production' || process.env.REACT_APP_BACKEND_ENV === BACKEND_ENVS.PRODUCTION) ?
//       'https://icast-backend-prod-41da93a76d4f.herokuapp.com/graphql/' :
//       'https://icast-backend-prod-41da93a76d4f.herokuapp.com/graphql/';
// const BACKEND_GRAPHQL_URL =
//     (process.env.NODE_ENV ==='production' || process.env.REACT_APP_BACKEND_ENV === BACKEND_ENVS.PRODUCTION) ?
//       'http://localhost:4000/graphql/' :
//       'http://localhost:4000/graphql/';

// const BACKEND_GRAPHQL_URL =
//       (process.env.NODE_ENV ==='production' || process.env.REACT_APP_BACKEND_ENV === BACKEND_ENVS.PRODUCTION) ?
//         'http://localhost:4000/graphql/' :
//         'http://localhost:4000/graphql/';

export {
  BACKEND_GRAPHQL_URL
};
